.header {
  display: flex;
  flex-direction: row;

  align-items: stretch;
  justify-content: space-evenly;
  padding: 0.5rem;
  margin: 1rem 1rem 1rem;
  background: rgb(240, 224, 224);

  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.header > img {
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 50%;
}
.texts {
  display: flex;
  flex-direction: column;

  align-items: center;
  text-shadow: rgb(65, 68, 87) 1px 1.5px 1px;
}
.name {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-size: 1.3rem;
}

.texts > p {
  font-family: "Dosis", sans-serif;
  font-weight: 200;
  font-size: 0.75rem;
}

.socials {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 1rem;
}

a.card-social {
  display: flex;
  flex-direction: row;
  height: 3.5rem;

  margin: 0.2rem;

  align-items: center;

  background: #ffffff;

  border-radius: 5px;
}
a.card-social > div {
  display: flex;
  flex: 1;
  align-items: baseline;
  justify-content: space-between;
}

a.card-social > img {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 2.8rem;
  height: 2.8rem;
}

a.card-social > span {
  color: #4a4e69;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer > p {
  font-family: "Dosis", sans-serif;
  font-weight: 200;
  font-size: 0.75rem;
}
