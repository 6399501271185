* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
a:link {
  text-decoration: none;
}
body {
  background: #312e38;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  background: rgb(22, 32, 45);
  background: linear-gradient(
    160deg,
    rgba(22, 32, 45, 1) 17%,
    rgba(239, 191, 0, 1) 100%
  );
}
body,
input,
button {
  font-family: "Roboto Slab", serif;
  font-size: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 500;
}
button {
  cursor: pointer;
}
